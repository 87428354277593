<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        div(class="event-header") 近日開催イベント >>
          span(class="display-title")  イベント詳細
        div(class="event-content-detail")
          div(class="event-content-detail-img")
            img(:src="$store.state.system.config.storage_addr_list.public + eventInfo.banner_store_path")
          div(class="event-content-detail-name-content")
            span(v-if="eventInfo.type === 't3'" class="red-r-label") OD
            span(v-if="eventInfo.type === 't2'" class="orange-r-label") 準OD
            span {{ eventInfo.name }}
          div( v-if="isRecruiting" class="event-content-detail-center") <img src="/img/clock_icon_green.svg"> 募集日時 {{ eventInfo.recruitment_start_time | moment("YYYY.MM.DD HH:mm") }} ~ {{ eventInfo.recruitment_end_time | moment("YYYY.MM.DD HH:mm") }}
          div(class="event-content-detail-center") <img src="/img/clock_icon_green.svg"> 開催日時 {{ eventInfo.start_time | moment("YYYY.MM.DD HH:mm") }} ~ {{ eventInfo.end_time | moment("YYYY.MM.DD HH:mm") }}
          div(v-html="eventInfo.describe" class="event-content-detail-describe")
          div(v-html="eventNotice")
          div(class="entry-button" v-if="canJoin")
            //- 承認制
            img(v-if="eventInfo.is_approval" @click="entryEvent(eventInfo.id)" class="img-button" src="/img/sankakibou_p.png")
            //- 公開
            img(v-else="notJoinYet" @click="entryEvent(eventInfo.id)" class="img-button" src="/img/sanka_p.png")
          div(class="entry-button" v-if="!notJoinYet")
            button(disabled class="pink-button") 参加済み

      // 参加者
      div(class="content-right")
        div(class="event-label")
          div(@click="!isEventEnd?onTab1():()=>{}" :disabled="isEventEnd" :class="ui.status === 1 ? 'tab-active' : 'test-b'" v-if="ui.popup === 0" class="gray-label") 参加者
          div(@click="isEventStarted?onTab2():()=>{}" :disabled="!isEventStarted"  :class="ui.status === 2 ? 'tab-active' : 'test-b'" v-if="ui.popup === 0" class="gray-label") ﾗﾝｷﾝｸﾞ
        div(v-if="ui.status === 1 && !showViewerRankList && !showProfile" class="right-content-border")
          //- div(class="live-img-grid")
          //-     div(class="live-img-item" v-for="item in eventInfo.lives.data")
          //-       div
          //-         img(:src="getRoomBannerUrl(item)" @click="onClickRoomBanner(item.liveable_id,item.id)")
          div(class="accountList")
            div(v-for="item in entryStreamers" class="accountList-item")
              div(class="img-content")
                //- pan-thumb(:avatar="item.streamer_info.profile.avatar_store_path" :attribute="item.streamer_info.profile.attribute"  size="70px" @avatarClick="onAvatarClick(item.streamer_info.profile.id)")
                pan-thumb(:avatar="item.streamer_info.profile.avatar_store_path" :attribute="item.streamer_info.profile.attribute"  size="70px")
              div(class="account-name") {{ item.streamer_info.profile.account_name }}
              div(class="account-point")
                div(class="point-icon")
                <img src="/img/P_pink_icon.svg" class="P_icon">
                div {{ Number(item.rank_point).toLocaleString() }}
        // ランキング
        div(v-if="ui.status === 2 && !showViewerRankList && !showProfile" class="right-content-border")
          div(class="accountList")
            div(v-for="(item, index) in rankingStreamers" class="accountList-item")
              div(class="img-content")
                div(v-if="index < 3" class="rank-badge")
                  <img v-if="index === 0" src="/img/badge_gold.png">
                  <img v-if="index === 1" src="/img/badge_silver.png">
                  <img v-if="index === 2" src="/img/badge_copper.png">
                  span {{ index + 1 }}
                pan-thumb(:avatar="item.streamer_info.profile.avatar_store_path" :attribute="item.streamer_info.profile.attribute"  size="70px")
                //- pan-thumb(:avatar="item.streamer_info.profile.avatar_store_path" :attribute="item.streamer_info.profile.attribute"  size="70px" @avatarClick="onAvatarClick(item.streamer_info.profile.id)")
              div(class="account-name") {{ item.streamer_info.profile.account_name }}
              div(class="account-point")
                div(class="point-icon")
                <img src="/img/P_pink_icon.svg" class="P_icon">
                div {{ Number(item.rank_point).toLocaleString() }}
              img(@click="onCheering(item.streamer_info)" class="img-button" src="/img/ranking_p.png")
        div(v-if="showViewerRankList && !showProfile" class="right-content-border")
          button(@click="onCloseViewerRankList()" class="close-button") <i class="fas fa-times"></i>
          div(class="t-c")
            h5 {{ eventInfo.name }}
            h5 {{ streamer_info.profile.account_name }}
            h5 応援ランキング
          div(class="viewer-list-grid")
            div(v-for="(item, index) in listViewers" class="viewer-img-item")
              div(v-if="index < 3" class="rank-badge")
                <img v-if="index === 0" src="/img/badge_gold.png">
                <img v-if="index === 1" src="/img/badge_silver.png">
                <img v-if="index === 2" src="/img/badge_copper.png">
                span {{ index + 1 }}
              pan-thumb(:avatar="item.profile.avatar_store_path" :attribute="item.profile.attribute" :current_title="item.viewer_info.current_title" size="70px" @avatarClick="onAvatarClick(item.profile.id)")
              div(class="account-name") {{ item.profile.account_name }}
              div(class="account-point")
                div(class="point-icon")
                <img src="/img/P_pink_icon.svg" class="P_icon">
                div {{ Number(item.total_points).toLocaleString() }}
        div(class="pos-r" v-if="showProfile")
          component-profile-index(:profile_id="selectedProfileId" v-on:profileclose="onProfileClose()")


    component-footer-index
</template>

<style src="./Detail.scss" lang="scss" scoped>
</style>

<script>

import { getLiveEvent, getLiveListEventEntryStreamer, getLiveListEventRanking, getEventLiveViewerRankList, putEntryEvent } from '@/api/live'
import PanThumb from '@/components/PanThumb'
import { serverMoment } from '@/utils/tools'
import moment from 'moment'

export default {
  name: "SettingEventManageDetail",
  components: {
    PanThumb
  },
  data() {
    return {
      ui: {
        // 1: tab1
        // 2: tab2
        status: 1,
        statusLast: 1,

        // 0: none
        // 1: cheering
        // 2: profile
        popup: 0,
        popupLast: 0,
      },
      entryStreamers: [],
      rankingStreamers: [],

      eventInfo: {streamers:{data:{}}},
      showingStreamerProfileId:0,
      selectedProfileId: 0,
      streamer_info: {},
      entryStreamerId:[],
      listViewers: [],

      showViewerRankList:false,
      showProfile:false,
      notJoinYet: true
    }
  },
  props: {},
  computed: {
    isRecruiting() {
      const now = serverMoment()
      const start = moment(this.eventInfo.recruitment_start_time)
      const end = moment(this.eventInfo.recruitment_end_time)
      if (now.isBefore(start) || now.isAfter(end)) {
        return false
      }
      return true
    },
    canJoin(){
      return (this.isRecruiting && this.notJoinYet)
    },
    isEventStarted(){
      const start = moment(this.eventInfo.start_time)
      return serverMoment() > start
    },
    isEventEnd(){
      const end = moment(this.eventInfo.end_time)
      return serverMoment() > end
    },
    eventNotice(){
      return this.$store.state.event.event_notice
    }
  },
  watch: {
    entryStreamerId() {
      this.notJoinYet = !this.entryStreamerId.includes(this.$store.state.user.id)
    }
  },
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const { data } = await getLiveEvent(this.$route.params.id);
      this.eventInfo = data;
      if(this.isEventEnd){
        this.ui.status = 2;
        this.syncEventRanking();
      }
      this.fetchEntryStreamerData()

      if(!this.eventNotice){
        this.$store.dispatch('event/syncEventNotice')
      }
    },
    async fetchEntryStreamerData() {
      const ret = await getLiveListEventEntryStreamer(this.$route.params.id);
      this.entryStreamers = ret.data.data;
      if(ret.data.data) {
        this.entryStreamerId = ret.data.data.map(data=>String(data.id))
      }
    },
    async syncEventRanking(){
      if(this.rankingStreamers.length > 0){
        return;
      }
      const { data } = await getLiveListEventRanking(this.$route.params.id);
      this.rankingStreamers = data.data;
    },
    async onCheering(streamer_info) {
      const { data } = await getEventLiveViewerRankList(this.eventInfo.id, streamer_info.streamer_id);
      this.listViewers = data;
      this.streamer_info = streamer_info;
      this.showViewerRankList = true;
      // this.ui.popup = 1;
    },
    async entryEvent(eventId) {
      // 非公開の状態でイベント参加はできません
      if (this.$store.state.user.profile.status !== "public") {
        this.$alert("このアカウントは現在非公開状態になっています。運営者の方へご確認ください。")
        return
      }
      try {
        await putEntryEvent(eventId);
        this.fetchEntryStreamerData()
        // 承認制
        if( this.eventInfo.is_approval ){
          this.$message.success('参加希望を送信しました。')
          return;
        }
        this.$message.success('参加成功しました')
      }catch(e) {
        console.log(e)
        this.$message.info('参加失敗しました')
      }
    },
    onCloseViewerRankList() {
      this.showViewerRankList = false
      // this.ui.popup = 0;
    },
    onTab1() {
      this.ui.status = 1;
    },
    onTab2() {
      this.ui.status = 2;
      this.syncEventRanking();
    },
    onStreamerAvatarClick(id) {
      this.showingStreamerProfileId = id;
      this.ui.popupLast = this.ui.popup;
      this.ui.statusLast = this.ui.status;
      this.ui.popup = 2;
    },
    profileclose() {
      this.ui.popup = this.ui.popupLast;
      this.ui.status = this.ui.statusLast;
    },
    onAvatarClick(profile_id){
      this.selectedProfileId = profile_id
      this.showViewerRankList = false
      this.showProfile = true
    },
    onProfileClose(){
      this.showProfile = false
      this.showViewerRankList = true
    }
    // getRoomBannerUrl(liveItem){
    //   return getImageUrl(liveItem.room_info.banner_store_path)
    // },
    // onClickRoomBanner(liveable_id,live_id){
    //   console.log(liveable_id)
    //   let link = `https://dev.a-live.tokyo/top/live/normal/${live_id}`
    //   window.open(link,'_blank');
    // }
  }
};
</script>
